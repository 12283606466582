import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Sidebar from '../../components/universities/sidebar';
import AnkerIntro from '../../components/home/anker-intro';
import * as styles from './Introduction.module.scss';

class Introduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const temp = {
      banner: 'https://cdn.shopify.com/s/files/1/0586/4941/1793/files/banner_5298c819-dbf9-4a3f-bd21-6de6b5f0f496.jpg?v=1646902912',
      mobileBanner: 'https://cdn.shopify.com/s/files/1/0586/4941/1793/files/375_128banner.jpg?v=1646902912',
      intro: {
        tipsTile: '致力于用行业领先的智能硬件+品牌出海全价值链<br/>赋能每一位怀揣梦想的智能硬件创业者<br/>打造下一个十五亿级出海智能硬件产品<br/>帮助实现创造用户价值的产品梦想<br/>收获创业成功的体验和回报<br/>',
        title: '我们用百万级年薪，持续寻找这样的人',
        content: [
          "<p class='subPoint'>智能硬件产品大将</p>",
          "<p class='subPoint'>视野开阔的领导者</p>",
          "<p class='subPoint'>结果导向的决胜者</p>",
        ],
        image: [
          'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/project_entrepreneur_content_1_d39e4a18a0.png',
          'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/production/project_entrepreneur_content_1_0b0b8a9848.png',
          'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/production/project_entrepreneur_content_3_5d1e8cb4fe.png',
        ],
      },
      section2: [
        {
          text: '项目简介',
          url: '/project/introduction',
        },
        {
          text: '项目亮点',
          url: '/project/highlights',
        },
        {
          text: '职位信息',
          url: '/project/job',
        },
        {
          text: '创业者案例',
          url: '/project/entrepreneurcase',
        },
      ],
      seo: {
        title: '安克创新招聘.安克创新校园招聘.加入安克创新',
        keywords: ['安克创新招聘', '长沙上市公司招聘', '跨境电商公司招聘', '长沙互联网公司招聘', 'Anker招聘', '智能硬件出海公司招聘'],
        description: '作为标杆出海企业，安克创新致力于在全球市场塑造中国消费电子品牌，通过不断创新，将富有科技魅力的领先产品带向全球消费者，弘扬中国智造之美。安克创新现成功打造了3个年营收过15亿的出海品牌，拥有全球100多个国家与地区的7500万用户。',
      },
    };
    const { location, data } = this.props;
    const copywriting = data.contentfulCopywriting;
    const { pathname } = location || {};
    return (
      <div className={styles.home}>
        <img className="pageBanner" src={temp.banner} />
        <img className="mobileBanner" src={temp.mobileBanner} />
        <Layout {...this.props} hideSearch={true} copywriting={copywriting}>
          <SEO title={temp.seo.title} keywords={temp.seo.keywords} description={temp.seo.description} />
          <div className={'projectIntroduction introduction'}>
            <Sidebar pathname={pathname} temp={temp.section2}></Sidebar>
            <div className="userIntroContainer">
              <p className="tipsTile" dangerouslySetInnerHTML={{ __html: temp.intro.tipsTile }}></p>
              <AnkerIntro temp={temp.intro}></AnkerIntro>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Introduction;

export const query = graphql`
query introductionQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
  }
} 
`;
